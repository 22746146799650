import { ElNotification } from 'element-plus'
import { ERROR, SUCCESS } from '@/assets/svgs'
import { useAppStore } from '@/store/modules/app'
import { computed } from 'vue'

const appStore: any = useAppStore()
const isTgLogin = computed(() => appStore.getIsTgLogin)

const NotificationService = {
  success(title: string, message: string) {
    ElNotification({
      title,
      message,
      icon: SUCCESS,
      position: 'bottom-left',
      customClass: 'notification',
      offset: isTgLogin.value ? 60 : 0
    })
  },
  error(title: string, message: string) {
    ElNotification({
      title,
      message,
      icon: ERROR,
      position: 'bottom-left',
      customClass: 'notification',
      offset: isTgLogin.value ? 60 : 0
    })
  },
  warning(title: string, message: string) {
    ElNotification({
      title,
      message,
      position: 'bottom-left',
      customClass: 'notification',
      offset: isTgLogin.value ? 60 : 0
    })
  },
  info(title: string, message: string) {
    ElNotification({
      title,
      message,
      position: 'bottom-left',
      customClass: 'notification',
      offset: isTgLogin.value ? 60 : 0
    })
  }
}

export default NotificationService
