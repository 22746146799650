import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    meta: {
      hidden: true,
      noTagsView: true
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'RedirectPage',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/home',
    component: Layout,
    redirect: '/home',
    meta: {},
    name: 'Home',
    hidden: true,
    children: [
      {
        path: '/home',
        component: () => import('@/views/Home/index.vue'),
        name: 'HomePage',
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    path: '/lease',
    component: Layout,
    redirect: '/lease',
    meta: {},
    name: 'Lease',
    hidden: true,
    children: [
      {
        path: '/lease',
        component: () => import('@/views/Lease/index.vue'),
        name: 'LeasePage',
        meta: {
          title: '能量租赁'
        }
      }
    ]
  },
  {
    path: '/number',
    component: Layout,
    redirect: '/number',
    meta: {},
    name: 'Number',
    hidden: true,
    children: [
      {
        path: '/number',
        component: () => import('@/views/Number/index.vue'),
        name: 'NumberPage',
        meta: {
          title: '笔数套餐'
        }
      }
    ]
  },
  {
    path: '/recharge',
    component: Layout,
    redirect: '/recharge',
    meta: {},
    name: 'Recharge',
    hidden: true,
    children: [
      {
        path: '/recharge',
        component: () => import('@/views/Recharge/index.vue'),
        name: 'RechargePage',
        meta: {
          title: '充值'
        }
      },
      {
        path: '/recharge/record',
        component: () => import('@/views/Recharge/Record.vue'),
        name: 'RechargeRecord',
        meta: {
          title: '充值记录'
        }
      }
    ]
  },
  {
    path: '/withdrawal',
    component: Layout,
    redirect: '/withdrawal',
    meta: {},
    name: 'Withdrawal',
    hidden: true,
    children: [
      {
        path: '/withdrawal',
        component: () => import('@/views/Withdrawal/index.vue'),
        name: 'WithdrawalPage',
        meta: {
          title: '提币'
        }
      },
      {
        path: '/withdrawal/record',
        component: () => import('@/views/Withdrawal/Record.vue'),
        name: 'WithdrawalRecord',
        meta: {
          title: '提币记录'
        }
      }
    ]
  },
  {
    path: '/rent',
    component: Layout,
    redirect: '/rent',
    meta: {},
    name: 'Rent',
    hidden: true,
    children: [
      {
        path: '/rent',
        component: () => import('@/views/Rent/index.vue'),
        name: 'RentPage',
        meta: {
          title: '租用记录'
        }
      }
    ]
  },
  {
    path: '/change',
    component: Layout,
    redirect: '/change',
    meta: {},
    name: 'Change',
    hidden: true,
    children: [
      {
        path: '/change',
        component: () => import('@/views/Change/index.vue'),
        name: 'ChangePage',
        meta: {
          title: '租用记录'
        }
      }
    ]
  },
  {
    path: '/monitor',
    component: Layout,
    redirect: '/monitor',
    meta: {},
    name: 'Monitor',
    hidden: true,
    children: [
      {
        path: '/monitor',
        component: () => import('@/views/Monitor/index.vue'),
        name: 'MonitorPage',
        meta: {
          title: '监听地址'
        }
      },
      {
        path: '/monitor/record',
        component: () => import('@/views/Monitor/Record.vue'),
        name: 'MonitorRecord',
        meta: {
          title: '监控交易记录'
        }
      }
    ]
  },
  {
    path: '/keys',
    component: Layout,
    redirect: '/keys',
    meta: {},
    name: 'Keys',
    hidden: true,
    children: [
      {
        path: '/keys',
        component: () => import('@/views/Keys/index.vue'),
        name: 'KeysPage',
        meta: {
          title: 'API密钥'
        }
      }
    ]
  },
  {
    path: '/recommend',
    component: Layout,
    redirect: '/recommend',
    meta: {},
    name: 'Recommend',
    hidden: true,
    children: [
      {
        path: '/recommend',
        component: () => import('@/views/Recommend/index.vue'),
        name: 'RecommendPage',
        meta: {
          title: '我的推荐'
        }
      },
      {
        path: '/recommend/details',
        component: () => import('@/views/Recommend/Details.vue'),
        name: 'RecommendDetails',
        meta: {
          title: '订单明细'
        }
      }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting',
    meta: {},
    name: 'Setting',
    hidden: true,
    children: [
      {
        path: '/setting',
        component: () => import('@/views/Setting/index.vue'),
        name: 'SettingPage',
        meta: {
          title: '全局设置'
        }
      }
    ]
  },
  {
    path: '/help',
    component: Layout,
    redirect: '/help',
    meta: {},
    name: 'Help',
    hidden: true,
    children: [
      {
        path: '/help',
        component: () => import('@/views/Help/index.vue'),
        name: 'HelpPage',
        meta: {
          title: '帮助中心'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: [...constantRouterMap, ...asyncRouterMap] as RouteRecordRaw[],
  // scrollBehavior: () => ({ left: 0, top: 0 })
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果有保存的位置（如浏览器后退），滚动到保存的位置
      return savedPosition
    } else if (to.hash) {
      const element = document.querySelector(to.hash) // 查找锚点元素
      if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY - 80 // 距离顶部80px
        window.scrollTo({ top: offset, behavior: 'smooth' }) // 平滑滚动到目标位置
      }
    } else {
      // 默认滚动到顶部
      return { left: 0, top: 0 }
    }
  }
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
