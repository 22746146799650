<template>
  <el-dialog
    :model-value="dialogVisible"
    class="dialog re-success"
    :width="width"
    :before-close="handleClose"
  >
    <div class="py-8 px-7 bg-[#202226] rounded-[3px]">
      <div class="flex items-center justify-between">
        <span class="text-lg text-white">{{ title }}</span>
        <resuccess class="cursor-pointer" @click="handleClose" />
      </div>
      <slot name="default"></slot>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
const { dialogVisible, title } = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 393
  }
})
const emit = defineEmits(['close'])

const handleClose = () => {
  emit('close')
}
</script>

<style lang="less">
.dialog {
  margin: 0 !important;
}
.re-success {
  border-radius: 3px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 3px;
  }
}
.el-overlay .el-overlay-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
