// 引入windi css
import '@/plugins/windi.css'

// 导入全局的svg图标
import '@/plugins/svgIcon'

import 'element-plus/theme-chalk/display.css'

// 初始化多语言
// import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import * as SvgIcon from './assets/svgs'

// 引入全局样式
import '@/styles/index.less'

// 路由
import { setupRouter } from './router'

// 权限
import { setupPermission } from './directives'

import { createApp } from 'vue'

import './styles/var.css'

import App from './App.vue'

import './permission'

import VueLuckyCanvas from '@lucky-canvas/vue'

//公共按钮组件
import globalComponents from '@/utils/globalComponents'

import NotificationService from '@/components/Notification/index'

import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import router from './router'

router.afterEach((to) => {
  const baseTitle = import.meta.env.VITE_APP_TITLE || 'THCAL'
  document.title = to.meta.title ? `${baseTitle} | ${to.meta.title}` : baseTitle
})

// 创建实例
const setupAll = async () => {
  const app = createApp(App)

  app.config.globalProperties.$notifyService = NotificationService

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  for (const [key, component] of Object.entries(SvgIcon)) {
    app.component(key, component)
  }

  // await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)

  setupPermission(app)

  app.use(ElementPlus)

  app.use(VCalendar, {})

  app.use(globalComponents)

  app.use(VueLuckyCanvas)

  app.mount('#app')
}

setupAll()
