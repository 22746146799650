<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore: any = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)
const greyMode = computed(() => appStore.getGreyMode)

// 获取当前链接参数
const getQueryString = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

onMounted(() => {
  if (getQueryString('code')) appStore.setReferralCode(getQueryString('code'))
})
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: var(--bg-color);
  color: #fff;
  font-family: PingFang SC !important;
  box-sizing: border-box;
}

#app {
  font-size: 14px;
}

ul,
li {
  list-style: none;
}
.nowrap {
  white-space: nowrap;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.notification {
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0.375rem !important;
  background-color: #2e3035 !important;
  padding: 1rem 1.5rem !important;
  box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.25) !important;
  z-index: 9999 !important;
  .el-notification__title {
    font-size: 0.875rem !important;
    color: #fff !important;
  }
  .el-notification__content {
    text-align: left;
    font-size: 0.875rem !important;
    margin-top: 0.5rem !important;
    color: #fff !important;
  }
  .el-notification__icon {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }
  .el-notification__closeBtn {
    color: #67707b !important;
  }
}
.transformx {
  transform: translateX(-50%);
}
.transformy {
  transform: translateY(-50%);
}

.bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

@media (max-width: 768px) {
  .notification {
    width: fit-content;
    padding: 1rem !important;
    align-items: center;
    .el-notification__title {
      display: none;
    }
    .el-notification__group {
      margin: 0;
    }
    .el-notification__content {
      margin: 0 0 0 0.625rem !important;
      text-align: left;
    }
    .el-notification__closeBtn {
      display: none;
    }
  }
}

.el-scrollbar__thumb {
  display: none;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
