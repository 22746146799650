import { defineStore } from 'pinia'
import { store } from '../index'
import { useCache } from '@/hooks/web/useCache'
import { LayoutType } from '@/types/layout'

const { wsCache } = useCache()

export const useAppStore = defineStore('app', {
  persist: () => {
    return {
      enabled: true,
      strategies: [
        {
          // 默认使用 localStorage
          key: 'my_user_store',
          storage: window.localStorage
        }
      ]
    }
  },
  state: (): any => {
    return {
      isTgLogin: wsCache.get('isTgLogin') || false, //是否tg登录
      referralCode: wsCache.get('referralCode') || '', //推荐码
      userAgent: '',
      reData: {}, //充值数据
      isReSuccess: false, //充值成功弹窗
      isMobile: false,
      isSignIn: false, //登录注册弹窗
      userInfo: 'userInfo', // 登录信息存储字段-建议每个项目换一个字段，避免与其他项目冲突
      mobile: false, // 是否是移动端
      title: import.meta.env.VITE_APP_TITLE, // 标题
      pageLoading: false, // 路由跳转loading
      layout: wsCache.get('layout') || 'classic' // layout布局
    }
  },
  getters: {
    getIsTgLogin(): boolean {
      return this.isTgLogin
    },
    getReferralCode(): boolean {
      return this.referralCode
    },
    getUserAgent(): string {
      return this.userAgent
    },
    getReData(): boolean {
      return this.reData
    },
    getReSuccess(): boolean {
      return this.isReSuccess
    },
    getSignIn(): boolean {
      return this.isSignIn
    },
    getUserInfo(): any {
      return this.userInfo
    },
    getMobile(): boolean {
      return this.mobile
    },
    getTitle(): string {
      return this.title
    },
    getPageLoading(): boolean {
      return this.pageLoading
    },
    getLayout(): LayoutType {
      return this.layout
    }
  } as any,
  actions: {
    setIsTgLogin(isTgLogin: boolean) {
      this.isTgLogin = isTgLogin
      wsCache.set('isTgLogin', this.isTgLogin)
    },
    setReferralCode(referralCode: any) {
      this.referralCode = referralCode
      wsCache.set('referralCode', this.referralCode)
    },
    setUserAgent(userAgent: any) {
      this.userAgent = userAgent
      wsCache.set('userAgent', this.userAgent)
    },
    setReData(reData: any) {
      this.reData = reData
    },
    setReSuccess(isReSuccess: boolean) {
      this.isReSuccess = isReSuccess
    },
    setSignIn(isSignIn: boolean) {
      this.isSignIn = isSignIn
    },
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo
    },
    setMobile(mobile: boolean) {
      this.mobile = mobile
    },
    setTitle(title: string) {
      this.title = title
    },
    setPageLoading(pageLoading: boolean) {
      this.pageLoading = pageLoading
    },
    setLayout(layout: LayoutType) {
      if (this.mobile && layout !== 'classic') {
        return
      }
      this.layout = layout
      wsCache.set('layout', this.layout)
    }
  } as any
} as any)

export const useAppStoreWithOut = () => {
  return useAppStore(store)
}
