<template>
  <div
    @click="click"
    :class="[
      disabled ? 'cursor-not-allowed disabled' : 'cursor-pointer',
      'flex items-center justify-center font-medium'
    ]"
    :style="{
      background: bg,
      color: color
    }"
  >
    <slot name="icon"></slot>
    <span>{{ name }}</span>
  </div>
</template>

<script setup lang="ts">
const { name, bg, color, disabled } = defineProps({
  name: {
    type: String,
    default: ''
  },
  bg: {
    type: String,
    default: '#14DECC'
  },
  color: {
    type: String,
    default: '#202028'
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const click = () => {
  emit('click')
}
</script>

<style scoped lang="less">
&.disabled {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(50, 52, 59, 0.6);
    z-index: 3;
    cursor: no-drop;
    border-radius: 3px;
  }
}
</style>
