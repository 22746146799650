import { defineStore } from 'pinia'
import { store } from '../index'
import zh_CN from 'element-plus/es/locale/lang/zh-cn'
import en_US from 'element-plus/es/locale/lang/en'
import ja_JP from 'element-plus/es/locale/lang/ja'
import ko_KO from 'element-plus/es/locale/lang/ko'
import { useCache } from '@/hooks/web/useCache'
import { LocaleDropdownType } from '@/types/localeDropdown'
// import { queryLang } from '@/utils/publicRequest'

const { wsCache } = useCache()

const elLocaleMap = {
  zh_CN,
  en_US,
  ja_JP,
  ko_KO
}

interface LocaleState {
  currentLocale: LocaleDropdownType
  localeMap: LocaleDropdownType[]
}

export const useLocaleStore = defineStore('locales', {
  persist: true,
  state: (): LocaleState => {
    return {
      currentLocale: {
        lang: wsCache.get('lang') || 'zh_CN',
        elLocale: elLocaleMap[wsCache.get('lang') || 'zh_CN']
      },
      // 多语言
      localeMap: []
    }
  },
  getters: {
    getCurrentLocale(): LocaleDropdownType {
      return this.currentLocale
    },
    getLocaleMap(): LocaleDropdownType[] {
      return this.localeMap
    }
  },
  actions: {
    setCurrentLocale(localeMap: LocaleDropdownType) {
      // this.locale = Object.assign(this.locale, localeMap)
      this.currentLocale.lang = localeMap?.lang
      this.currentLocale.elLocale = elLocaleMap[localeMap?.lang]
      wsCache.set('lang', localeMap?.lang)
    }
  }
})

// const getLang = async () => {
//   const res = await queryLang()
//   useLocaleStore().localeMap = res.data.list
// }
// getLang()

export const useLocaleStoreWithOut = () => {
  return useLocaleStore(store)
}
