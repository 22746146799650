import router from './router'
// import { useCache } from '@/hooks/web/useCache'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
// import { getDictApi } from '@/api/common'
// import userInfoStore from '@/store/modules/user'

const permissionStore = usePermissionStoreWithOut()

// const dictStore = useDictStoreWithOut()

// const { wsCache } = useCache()

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

// const userInfo=userInfoStore()

router.beforeEach(async (to, from, next) => {
  await permissionStore.generateRoutes()
  start()
  loadStart()
  next()
  // permissionStore.getAddRouters.forEach((route) => {
  //   console.log(route, 'route')
  //   router.addRoute(route as RouteRecordRaw) // 动态添加可访问路由表
  // })
  // if (getToken()) {
  //   if (to.path === '/signIn') {
  //     next({ path: '/' })
  //   } else {
  //     if (permissionStore.getIsAddRouters) {
  //       next()
  //       return
  //     }
  //     if (appStore.getDynamicRouter) {
  //       await permissionStore.generateRoutes()
  //     } else {
  //       await permissionStore.generateRoutes()
  //     }
  //     permissionStore.getAddRouters.forEach((route) => {
  //       console.log(route, 'route')
  //       router.addRoute(route as RouteRecordRaw) // 动态添加可访问路由表
  //     })
  //     const redirectPath = from.query.redirect || to.path
  //     const redirect = decodeURIComponent(redirectPath as string)
  //     const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
  //     permissionStore.setIsAddRouters(true)
  //     next(nextData)
  //   }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next()
  //   } else {
  //     next(`/signIn`) // 否则全部重定向到登录页
  //   }
  // }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})
